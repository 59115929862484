.about-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 60px;
  margin: 70px 10%;
}

.about-me-title h1 {
  padding: 0 30px;
  font-size: 60px;
  font-weight: 600;
  color: #bb86fc;
}

.about-me-section {
  display: flex;
  gap: 50px;
}

.right-section {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.about-text {
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-size: 18px;
  font-weight: 500;
}

.skills {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.skill {
  display: flex;
  gap: 20px;
  align-items: center;
  transition: 0.3s;
}

.skill p {
  min-width: 130px;
  font-size: 20px;
  font-weight: 500;
}

.skill hr {
  outline: none;
  border: none;
  width: 50%;
  height: 8px;
  border-radius: 50px;
  background: linear-gradient(264deg, #03dac6 -5%, #bb86fc 102.06%);
}

.achievements {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-bottom: 50px;
}

.achievement {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  transition: 0.5s;
}

.achievement h1 {
  font-size: 40px;
  font-weight: 500;
  background: linear-gradient(270deg, #03dac6 15%, #bb86fc 90.06%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.achievement p {
  font-size: 22px;
  font-weight: 500;
}

.achievement:hover {
  transform: scale(1.2);
  transition: 0.5s;
}

.skill:hover {
  transform: scale(1.05);
  transition: 0.3s;
}

@media (max-width: 768px) {
  .about-me-title h1 {
    color: #bb86fc;
    font-size: 40px;
    font-weight: 600;
    padding: 0;
  }

  .left-section {
    display: none;
  }
  .about-section {
    gap: 50px;
    margin: 50px 6%;
    align-items: start;
  }

  .about-text {
    line-height: 25px;
  }

  .skill p {
    font-size: 16px;
    min-width: 90px;
  }

  .skill hr {
    height: 6px;
  }

  .achievements {
    flex-direction: column;
    gap: 20px;
  }
  .achievement h1 {
    font-size: 25px;
  }

  .achievement p {
    font-size: 17px;
  }

  /* .achievements hr {
    display: none;
  } */
}
