.hero {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.hero img {
  margin-top: 30px;
  width: 240px;
  border-radius: 50%;
}

.hero h1 {
  text-align: center;
  width: 65%;
  font-size: 65px;
  font-weight: 600;
  line-height: 70px;
}

.hero h1 span {
  color: #03dac6;
}

.hero p {
  width: 50%;
  text-align: center;
  font-size: 18px;
  line-height: 30px;
}

.hero-action-btns {
  display: flex;
  align-items: center;
  gap: 25px;
  font-weight: 500;
  margin-bottom: 50px;
}

.hero-action-btns a {
  text-decoration: unset;
}

.hero-connect-btn {
  background: #bb86fc;
  padding: 25px 45px;
  border-radius: 50px;
  cursor: pointer;
  color: #191520;
  background: linear-gradient(267deg, #03dac6 0.36%, #bb86fc 102.06%);
  transition: 0.5s;
}

.hero-connect-btn:hover {
  transform: scale(1.05);
}

.hero-resume-btn {
  padding: 25px 45px;
  border-radius: 50px;
  cursor: pointer;
  text-decoration: none;
  color: #03dac6;
  border: solid 2px #03dac6;
  transition: 0.5s;
}

.hero-resume-btn:hover {
  border-color: #bb86fc;
  color: #bb86fc;
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .hero img {
    margin-top: 50px;
    width: 200px;
  }

  .hero h1 {
    margin-top: 10px;
    width: 90%;
    font-size: 35px;
    line-height: 50px;
  }

  .hero p {
    width: 80%;
    font-size: 16px;
    line-height: 25px;
    margin: 5px 0;
  }

  .hero-action-btns {
    gap: 10px;
    font-weight: 500;
    margin-bottom: 50px;
  }

  .anchor-link {
    padding: 20px;
    border-radius: 40px;
    font-size: 14px;
  }

  .hero-connect-btn {
    padding: 20px;
    border-radius: 40px;
    font-size: 14px;
  }

  .hero-resume-btn {
    padding: 18px 30px;
    border-radius: 40px;
    font-size: 14px;
  }
}
