.footer-section {
  margin: 50px 10%;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.logo {
  width: 120px;
  height: auto;
  margin-left: -7px;
}

.top {
  display: flex;
  justify-content: space-between;
}

.left p {
  font-size: 16px;
  max-width: 450px;
}

.right {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-left: 1rem;
}

.email-input {
  display: flex;
  gap: 20px;
  padding: 15px 20px;
  border-radius: 50px;
  background: #32323b;
}

.email-input:hover {
  border: 1px solid white;
}

input {
  outline: none;
  border: none;
  background: transparent;
  color: #a0a0a0;
  font-family: Outfit;
  font-size: 18px;
}

.subscribe-btn {
  background: linear-gradient(267deg, #03dac6 0.36%, #bb86fc 102.06%);
  font-size: 16px;
  padding: 18px 40px;
  border-radius: 50px;
  cursor: pointer;
  transition: 0.3s;
  color: white;
  border: unset;
}

.subscribe-btn:hover {
  transform: scale(1.1);
  transition: 0.3s;
}

.bottom {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}

.bottom-right {
  display: flex;
  gap: 30px;
  margin-bottom: 10px;
}

.bottom-right p {
  text-wrap: nowrap;
}

.bottom-left {
  margin-bottom: 1rem;
}

@media (max-width: 1040px) {
  .footer-section {
    margin: 50px 4%;
  }
}

@media (max-width: 768px) {
  .footer-section {
    margin: 50px 6%;
  }

  .top {
    flex-direction: column;
    gap: 30px;
  }

  .top .logo {
    margin: 0;
    margin-left: -7px;
  }

  .right {
    gap: 5px;
    margin-left: 0;
  }

  .email-input {
    padding: 12px 14px;
    max-width: 200px;
  }

  .subscribe-btn {
    font-size: 14px;
    padding: 14px 20px;
  }

  .bottom {
    display: flex;
    flex-direction: column-reverse;
    font-size: 14px;
  }

  .bottom-right {
    gap: 12px;
  }
}

.bottom-left span {
  display: block;
}
