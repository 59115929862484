.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 10%;
}

.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  gap: 60px;
  font-size: 20px;
}

.navbar .logo {
  width: 120px;
  height: auto;
  margin-bottom: 8px;
}

.nav-connect {
  padding: 15px 20px;
  border-radius: 50px;
  background: linear-gradient(267deg, #03dac6 0.36%, #bb86fc 102.06%);
  font-size: 16px;
  cursor: pointer;
  transition: 0.5s;
  color: #191520;
}

.nav-connect:hover {
  transform: scale(1.05);
}

.active {
  border-bottom: 3px solid #bb86fc;
  padding-bottom: 0.3rem;
}

ul.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  gap: 40px;
  font-size: 16px;
}

li {
  display: flex;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
  padding-bottom: 0.3rem;
}

.anchor-link {
  text-decoration: none;
  color: white;
}

.anchor-link p {
  transition: 0.2s;
}

.anchor-link p:hover {
  color: #03dac6;
  transform: scale(1.04);
}

.menu-close-btn {
  display: none;
}
.burger-open {
  display: none;
}

@media (max-width: 768px) {
  .navbar {
    margin: 20px 6%;
  }
  .nav-connect {
    display: none;
  }
  .burger-open {
    display: block;
    position: absolute;
    right: 23px;
    cursor: pointer;
    width: 30px;
  }

  .menu-close-btn {
    width: 20px;
    display: flex;
    margin-left: auto;
    padding: 1rem;
    margin-bottom: 3rem;
  }

  ul.nav-menu {
    position: fixed;
    flex-direction: column;
    top: 0;
    gap: 30px;
    background-color: #1f0016;
    width: 350px;
    height: 100%;
    z-index: 2;
    transition: right 0.5s;
    right: -350px;
    display: flex;
    align-items: flex-start;
    list-style: none;
    gap: 5px;
    font-size: 16px;
    background: #32323c;
  }

  li {
    font-size: 20px;
    padding-left: 1.5rem;
    flex-direction: row;
    gap: 20px;
  }

  .anchor-link {
    font-size: 22px;
    padding: unset;
    padding-bottom: 0.5rem;
    margin: 10px;
    border-radius: unset;
  }

  li.active {
    border-bottom: unset;
  }

  li.active .anchor-link {
    border-bottom: 3px solid #03dac6;
  }
}
