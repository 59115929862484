.my-work-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 80px;
  margin: 80px 80px;
}

.work-container a {
  text-decoration: none !important;
}
.title h1 {
  color: #bb86fc;
  padding: 0 30px;
  font-size: 60px;
  font-weight: 600;
}

.work-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
}

.work-title {
  color: #03dac6;
  padding-bottom: 0.3rem;
  padding-top: 0.2rem;
}

.work-description {
  color: white;
}

.porfolio-img-cont {
  flex: 1 1 300px; /* Flex-grow, shrink, and basis. Adjust the 300px as per your design */
  max-width: 300px; /* Adjust width according to preference */
  height: 0;
  padding-bottom: 75%; /* Maintain 4:3 aspect ratio (you can adjust this to 100% for square images) */
  position: relative;
  overflow: hidden;
  border-radius: 5%;
}

.work-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.work-container .porfolio-item:hover {
  transform: scale(1.05);
  transition: 0.3s;
}

.see-more-work-btn {
  text-decoration: none;
  color: white;
}

.show-more {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  border: 2px solid white;
  padding: 20px 40px;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
  transition: 0.6s;
  cursor: pointer;
}

.show-more:hover {
  gap: 30px;
  transition: 0.6s;
}

@media (max-width: 768px) {
  .my-work-section {
    gap: 50px;
    margin: 50px 6%;
    align-items: start;
  }

  .title h1 {
    padding: 0;
    font-size: 40px;
  }

  .work-container {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .show-more {
    padding: 15px 30px;
    font-size: 16px;
    margin: 10px auto;
  }
}
