@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");

* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 100%; /* Ensures font-size is 16px by default */
  box-sizing: border-box;
}

body {
  background: #2a2a2a;
  color: #e1e0e0;
  height: 100vh;
  font-family: "outfit";
  overflow-x: hidden;
}
